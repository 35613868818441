<template>
  <section class="homePage text-center">
    <img src="images/img1.png" alt="Know your Peak Inspiratory Flow" class="mx-auto d-block img-fluid w-100" />
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="homeTxt">
            <img src="images/logo.png" alt="Alare" class="mx-auto d-block img-fluid" />
            <p class="blueTxt">Pour connaître votre débit inspiratoire de pointe (DIP), une importante mesure de votre santé pulmonaire.</p>
			<p class="text-white">Know your Peak Inspiratory Flow (PIF) An Important measurement for your lung health</p>
			<div class="row">
				<div class="col-6 pr-1">
					<button class="btn blueBtn" @click="changeLanguage('fr')">Français</button>
				</div>	
				<div class="col-6 pl-1">
					<button class="btn blueBtn" @click="changeLanguage('en')">English</button>
				</div>
			</div>		
          </div>
        </div> 
      </div>
    </div>
    <permissionDenied />
  </section>
</template>
<script>
  import permissionDenied from '~/components/extra/permissionDenied.vue';
  export default {
    components: {
      permissionDenied,
    },
    methods: {
      changeLanguage(lang) {
        this.$i18n.locale = lang;
        this.$router.push('instructions/Video')
      }
    }
  }
</script>